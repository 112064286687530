import React from "react";
import DateFormating from './components/DateFormating';
import PrevPage from './components/PrevPage';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import AccountContentHeader from './components/AccountContentHeader';
import DeleteModal from './components/DeleteModal';
import Loading from './components/Loading';
import { Link, useParams, Navigate } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import { getAuth } from "firebase/auth";
import sprite from './media/icons.svg';
const customStyles = {
  control: () => ({
    height: 'auto',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class RewardsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      modalDisabled: false,
      modal: false,
      modalDeleteReward: false,
      rewardUsers: [],
      modalAddUser: false,
      usersCompany: [],
      addUserID: [],
      bonuseUserValue: '',
      messageUserValue: '',
      controlRow: null,
      modalDeleteUser: false,
      deleteObj: {},
      removeUserIds: [],
      modalStatusUser: false,
      statusObj: {},
      statusUserIndex: null
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const div = document.querySelector( '.control-wgt');
        if(div) {
          document.addEventListener('click', e => {
            const withinBoundaries = e.composedPath().includes(div);
            if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
              if ( ! withinBoundaries ) {
                this.setState( {
                  controlRow: null
                } );
              }
            }
        });
      }
    }
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        modalData: {},
        modalDeleteReward: false,
        modalAddUser: false,
        modalDeleteUser: false,
        removeUserIds: [],
        modalStatusUser: false,
        statusObj: {},
        statusUserIndex: null,
        messageUserValue: ''
      }));
    }, 500);
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token()
    });
    fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/${this.props.id}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: data.errors,
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            bonuseUserValue: data.bonus,
            isLoaded: true,
            rewardsData: data
          });
        })

        fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/${that.props.id}/user-rewards/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "direction": "DESC",
            "itemsPerPage": 20,
            "rewardID": that.props.id
          })
        }).then(res => {
          if(res.status !== 200) {

          } else {
            res.json().then(function(data){
              that.setState({
                rewardUsers: data.data
              });
            })
          }
        })

      }
    })
  }
  handlerEnabled(event){
    let that = this;
    console.log(that.state.rewardsData);
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/rewards/${that.props.id}/enable`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({"enable": !that.state.rewardsData.enabled})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              rewardsData: data
            })
          })
        }
      })
    });
  }
  handlerDeleteReward(event){
        let that = this;
        getAuth().currentUser.getIdToken().then(function(idToken){
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + idToken
          });
          fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/${that.props.id}`, {
            method: 'DELETE',
            headers: myHeaders
          }).then(res => {
            if (res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              if(typeof window !== "undefined" && typeof document !== 'undefined'){
                localStorage.setItem("successDelete", JSON.stringify({status: true, text: 'Успішно видалений'}))
              }
              that.setState({
                successDelete: true
              });
            }
          })
        })
  }
  handlerDeleteRewardModal(event){
    if(this.state.modalEvent){
      if(typeof window !== "undefined" && typeof document !== 'undefined') {
        document.querySelector('body').style.overflow = "";
      }
        this.setState(prevState => ({
          modalDisabled: true
        }));
        setTimeout(() => {
          this.setState(prevState => ({
            modalDisabled: false,
            modalReward: false,
            modalDeleteReward: true
          }));
        }, 500);
    }
    this.setState({
      modalDeleteReward: true
    });
  }
  handlerAddUserModal(event){
      let that = this;
      this.setState(prevState => ({
        modal: !prevState.modal,
        modalAddUser: !prevState.modalAddUser
      }));
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/users/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20})
        }).then(res => {
          if (res.status !== 200) {

          } else {
            res.json().then(function(data) {
              data.data.map((item, index) => {
                let name = (item.name ? item.name + ' ' : '') + (item.lastName ? item.lastName + ' ' : '') + (item.middleName ? item.middleName : '');
                that.setState(prevState => ({
                  usersCompany: [...prevState.usersCompany, {value: item.userID, label: name ? name : item.email} ]
                }));
              });
            });
          }
        })
      })
  }
  handlerChooseUser(event){
    this.setState({
      addUserID: event
    });
  }
  handlerBonuseUser(event){
    const inputValue = event.target.value;
    if (inputValue === "") {
        this.setState({
            bonuseUserValue: "",
            bonuseValueNumError: false,
            bonuseValueCountError: false
        });
        return;
    }
    if (/^\d+(\.\d{0,2})?$/.test(inputValue)) {
        const floatValue = parseFloat(inputValue);
        if (!isNaN(floatValue)) {
            if (floatValue > 999999.99) {
                this.setState({
                    bonuseValueCountError: true,
                    bonuseValueNumError: false
                });
            } else {
                this.setState({
                    bonuseUserValue: inputValue,
                    bonuseValueNumError: false,
                    bonuseValueCountError: false
                });
            }
        } else {
            this.setState({
                bonuseValueCountError: false,
                bonuseValueNumError: true
            });
        }
    } else {
      const floatValue = parseFloat(inputValue);
      if (!isNaN(floatValue)) {
        this.setState({
            bonuseValueCountError: true
        });
      } else {
        this.setState({
            bonuseValueNumError: true
        });
      }
    }
  }
  handlerMessageUser(event){
    this.setState({
      messageUserValue: event.target.value
    });
  }
  handlerAddUser(event){
    event.preventDefault();
    let that = this;
    let ids = this.state.addUserID.map(item => item.value);
    let obj = {
      "bonus": this.state.bonuseUserValue,
      "message": this.state.messageUserValue,
      "rewardID": this.props.id,
      "userIds": ids
    };
    console.log(obj);
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      console.log(obj);
      fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/${that.props.id}/user-rewards`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(obj)
      }).then(res => {
        if (res.status !== 200) {

        } else {

          fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/${that.props.id}`, {
            method: 'GET',
            headers: myHeaders
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  isLoaded: true,
                  errors: data.errors,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState({
                  isLoaded: true,
                  rewardsData: data
                });
              })

              fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/${that.props.id}/user-rewards/search`, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                  "direction": "DESC",
                  "itemsPerPage": 20,
                  "rewardID": that.props.id
                })
              }).then(res => {
                if(res.status !== 200) {

                } else {
                  res.json().then(function(data){
                    console.log(data);
                    that.setState({
                      rewardUsers: data.data
                    });
                  })
                }
              })

            }
          })

          res.json().then(function(data) {
            that.handleModalDisabled();
          });
        }
      })
    })
  }

  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }

  handlerDeleteUser(index, event){
    this.setState(prevState => ({
      modalDeleteUser: !prevState.modalDeleteUser,
      deleteObj: this.state.rewardUsers[index],
      removeUserIndex: index
    }));
    if(this.state.rewardUsers[index].rewardByDate.length < 2){
      const { removeUserIds } = this.state;
      this.setState({
        removeUserIds: [...removeUserIds, this.state.rewardUsers[index].rewardByDate[0].userRewardID]
      })
    }
  }

  handlerChangeRemoveUser(id, event) {
    const { removeUserIds } = this.state;
    const isChecked = event.target.checked;

    if (isChecked) {
      // Если чекбокс отмечен, добавляем id в массив
      this.setState({
        removeUserIds: [...removeUserIds, id],
      });
    } else {
      // Если чекбокс не отмечен, удаляем id из массива
      this.setState({
        removeUserIds: removeUserIds.filter(userId => userId !== id),
      });
    }
  }
  handlerRemoveUser(event){
    event.preventDefault();
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/${that.props.id}/user-rewards`, {
        method: 'DELETE',
        headers: myHeaders,
        body: JSON.stringify({
        "ids": that.state.removeUserIds
      })
      }).then(res => {
        if (res.status !== 200) {

        } else {
          let rewardUsers = that.state.rewardUsers;
          let rewardByDate = rewardUsers[that.state.removeUserIndex].rewardByDate;
          if(rewardByDate.length < 2){
            rewardUsers.splice(that.state.removeUserIndex, 1);
            that.setState({ rewardUsers: rewardUsers });
          } else {
            let rewardUsers = that.state.rewardUsers;
            let rewardByDate = rewardUsers[that.state.removeUserIndex].rewardByDate;
            let filteredRewardUsers = rewardByDate.filter(user => !that.state.removeUserIds.includes(user.userRewardID));
            rewardUsers[that.state.removeUserIndex].rewardByDate = filteredRewardUsers;
            if(filteredRewardUsers.length < 1){
              rewardUsers.splice(that.state.removeUserIndex, 1);
              that.setState({ rewardUsers: rewardUsers });
            } else {
              that.setState({ rewardUsers: rewardUsers });
            }
          }
          that.handleModalDisabled();
        }
      })
    })

  }

  handlerStatusUser(index, event){
    this.setState(prevState => ({
      modalStatusUser: !prevState.modalStatusUser,
      statusObj: this.state.rewardUsers[index],
      statusUserIndex: index
    }));
  }

  handlerChangeStatusUser(index, enable, userRewardID, event){
    event.preventDefault();
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/${that.props.id}/user-rewards/${userRewardID}/enable`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
        "enable": !enable
        })
      }).then(res => {
        if (res.status !== 200) {

        } else {
          let rewardUsers = that.state.rewardUsers;
          let rewardByDate = rewardUsers[that.state.statusUserIndex].rewardByDate[index];
          res.json().then(function(data) {
            rewardByDate.enable = data.enabled;
            that.setState({ rewardUsers: rewardUsers });
          })
        }
      })
    })
  }

  /* Handler for change status */
  handlerEnabled(event){
    let that = this;
    let rewardsData = that.state.rewardsData;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/${that.props.id}/enable`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({"enable": rewardsData.enabled ? false : true})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            rewardsData.enabled = data.enabled;
            that.setState({
              rewardsData: rewardsData
            })
          })
        }
      })
    });
  }

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = (this.state.rewardsData && this.state.rewardsData.name ? this.state.rewardsData.name + ' | ' : '') + this.props.companyName;
    }
    let qw;
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      qw = window.location.protocol;
    }
    let userRewards;
    let userRewardsCount = '';
    if(this.state.isLoaded){
      userRewards = this.state.rewardsData.userRewards;
      if(userRewards === 0){
        userRewardsCount = userRewards + ' ' + this.props.getTranslate('countUsers-ZeroAndBiggestFour');
      } else if(userRewards === 1){
        userRewardsCount = userRewards + ' ' + this.props.getTranslate('countUsers-One');
      } else if(userRewards > 1 && userRewards < 5) {
        userRewardsCount = userRewards + ' ' + this.props.getTranslate('countUsers-BetweenTwoAndFour');
      } else {
        userRewardsCount = userRewards + ' ' + this.props.getTranslate('countUsers-ZeroAndBiggestFour');
      }
    }
    let resultRewards = '';
    if(this.state.error) {
      resultRewards = <div className="errors-content">{this.props.getTranslate('loading-ErrorRenderContent')}</div>;
    } else if(!this.state.isLoaded) {
      resultRewards = <Loading />;
    } else {
      resultRewards = <div className="account-content__content">
        <div className="bonuses-info-content">
          <div className="account-wgt bonuses-info">
            <div className="bonuses-info__content">
              <div className="bonuses-info__content-inner">
                <div className="bonuses-info__content-header">
                  <div className="bonuses-info__content-header-inner">
                    {this.state.rewardsData && this.state.rewardsData.photo && this.state.rewardsData.photo.url ? <div className="bonuses-info__logo-wrap"><img className="bonuses-info__logo" src={qw === 'http:' ? this.state.rewardsData.photo.url.replace( /https:/g, "http:" ) : this.state.rewardsData.photo.url} alt="" /></div> : ''}
                    <h2 className="bonuses-info__headline">{this.state.rewardsData ? this.state.rewardsData.name : ''}</h2>
                    <div className={`status-label status-label_theme_${this.state.rewardsData.enabled ? 'green' : 'red'}`}>{this.state.rewardsData.enabled ? this.props.getTranslate('rewards-StatusActive') : this.props.getTranslate('rewards-StatusNotActive')}</div>
                  </div>
                  <div className="user-statistic__info-right user-statistic__info-right-desktop view-page-nav">
                    <div className="user-statistic__info-nav">
                      <Link className="user-statistic__info-nav-item btn-edit" to={this.props.getPermalink(`/account/rewards/edit/${this.props.id}`)}>
                        <svg className="btn-edit__icon">
                          <use href={`${sprite}#edit-icon`}></use>
                        </svg>
                      </Link>
                      <button className="btn-edit btn-edit_theme_remove" type="button" onClick={this.handlerDeleteRewardModal.bind(this)}>
                        <svg className="btn-edit__icon">
                          <use href={`${sprite}#delete-red`}></use>
                        </svg>
                      </button>
                      <div className="tg-list-item">
                        <input id="reward-status" className="tgl tgl-light" type="checkbox" checked={this.state.rewardsData.enabled} onChange={this.handlerEnabled.bind(this)} /><label className="tgl-btn" htmlFor="reward-status"></label>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.rewardsData && <div className="bonuses-info__description" dangerouslySetInnerHTML={{ __html: this.state.rewardsData.description }}></div>}
                <div className="bonuses-info__wgts-info">
                  {this.state.rewardsData.bonus ? <div className="bonuses-info__wgt">
                    <div className="bonuses-info__wgt-inner">
                      <div className="bonuses-info__wgt-label">{this.props.getTranslate('RewardBonus-BonusForReward')}</div>
                      <div className="bonuses-info__wgt-value">{this.state.rewardsData.bonus + ' ' + this.props.currency}</div>
                    </div>
                  </div> : ''}
                  {this.state.rewardsData.rewardType && this.state.rewardsData.rewardSubType ? <><div className="bonuses-info__wgt">
                    <div className="bonuses-info__wgt-inner">
                      <div className="bonuses-info__wgt-label">Умова виконання:</div>
                      <div className="bonuses-info__wgt-value">{this.props.getTranslate('RewardType-' + this.state.rewardsData.rewardType)}</div>
                    </div>
                  </div>
                  <div className="bonuses-info__wgt">
                    <div className="bonuses-info__wgt-inner">
                      <div className="bonuses-info__wgt-label">Значення:</div>
                      <div className="bonuses-info__wgt-value">{this.props.getTranslate('RewardType-' + this.state.rewardsData.rewardSubType)}</div>
                    </div>
                  </div></> : ''}
                </div>
              </div>
            </div>
          </div>
          <div className="bonuses-info__users">
            <button className="btn btn_with_icon team-nav__btn" type="button" onClick={this.handlerAddUserModal.bind(this)}>
              <svg className="btn__icon-add">
                <use href={`${sprite}#plus-icon`}></use>
              </svg>{this.props.getTranslate('usersAdd-ModalBtn')}</button>
            <div className="bonuses-info__users-content">
              <div className="bonuses-info__users-content-wgt">{userRewardsCount}</div>
            </div>
          </div>
          {this.state.rewardUsers && this.state.rewardUsers.length > 0 ? <table className="data-table">
            <thead>
              <tr>
                <th>{this.props.getTranslate('RewardViewTable-Users')}</th>
                <th>{this.props.getTranslate('RewardViewTable-Amount')}</th>
                <th>{this.props.getTranslate('RewardViewTable-Date')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {this.state.rewardUsers.map((item, index) => {
              let classRow = '';
              if(this.state.removeIndex === index){
                classRow = ' row-disabled';
              } else {
                classRow = '';
              }
              const itemsEnable = item.rewardByDate.every(item => !item.enable);
              return <tr className={`standart-table__row ${classRow}`} key={index}>
                <td>
                  <div className="bonuses-info__user">
                    <img src={qw === 'http:' ? item.userPhoto.replace( /https:/g, "http:" ) : item.userPhoto} alt="" />{item.userName}
                  </div>
                </td>
                <td>{item.rewardByDate.length}</td>
                <td>
                  <div className={item.rewardByDate.length > 1 ? 'reward-date-tooltip' : ''}>
                    <DateFormating start={item.rewardByDate[0].createdDate} time="off" />
                    {item.rewardByDate.length > 1 ? <div className="reward-date-tooltip__content">
                    {item.rewardByDate.map((item, index) => {
                      return <div className={`reward-date-tooltip__content-item${!item.enable ? ' reward-date-tooltip__content-item_disabled' : ''}`} key={index}>
                        <DateFormating start={item.createdDate} time="off" />
                        {!item.enable ? <svg className="reward-date-tooltip__content-item-icon">
                          <use href={`${sprite}#hide-pass`}></use>
                        </svg> : ''}
                      </div>
                    })}
                    </div> : ''}
                  </div>
                </td>
                <td className="team-table__control">
                <div className="data-table__control-inner">
                  {itemsEnable ? <div className="data-table__hide">
                    <svg className="data-table__hide-icon">
                      <use href={`${sprite}#hide-pass`}></use>
                    </svg>
                    <div className="data-table__hide-content">{this.props.getTranslate('tableEnable-TooltipMessage')}</div>
                  </div> : ''}
                  <div className="control-wgt control-wgt__pos">
                    <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                      <svg className="control-btn__icon">
                        <use href={`${sprite}#more-icon`}></use>
                      </svg>
                    </button>
                    <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                      <ul className="control-content__list">
                        <li>
                          <button className="control-content__btn" type="button" onClick={this.handlerStatusUser.bind(this, index)}>
                            <svg className="control-content__icon">
                              <use href={`${sprite}#view-icon-stroke`}></use>
                            </svg>
                            {item.rewardByDate[0].enable ? this.props.getTranslate('tableDropdown-StatusLabel') : this.props.getTranslate('tableDropdown-StatusLabelShow')}</button>
                        </li>
                        <li>
                          <button className="control-content__btn control-content__btn_delete" onClick={this.handlerDeleteUser.bind(this, index)}>
                            {this.state.indexRowEvent !== index ? <svg className="control-content__icon">
                              <use href={`${sprite}#delete-red`}></use>
                            </svg> : <Loading />}{this.props.getTranslate('tableDropdown-EditRemove')}</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  </div>
                </td>
              </tr>
            })}
            </tbody>
          </table> : ''}
        </div>
      </div>
    }
     return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successDelete && <Navigate to={this.props.getPermalink("/account/rewards")} replace={true} />) : ''}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap" onClick={this.handlerCloseElements.bind(this)}>
    <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
          prevPageBtn={<PrevPage text={this.props.getTranslate('buttonBack-buttonText')}/>}
          backBtn={true}
          backBtnType="link"
          backBtnClass="button-back_theme_gray"
          backBtnHandler={this.props.getPermalink("/account/rewards")}
          backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
        />

        {resultRewards}

        <div className="account-content__footer">

        </div>
      </div>
    </div>
    </div>

    {/* Delete item modal */}
    <DeleteModal
      modalStatus={this.state.modalDeleteReward}
      modalDisabledStatus={this.state.modalDisabled}
      modalDisabledClass={this.state.addClass}
      handlerModalDisabled={this.handleModalDisabled}
      modalTitle={this.props.getTranslate('modal-deleteRewardTitle')}
      modalDescription={this.props.getTranslate('modal-deleteRewardDescription')}
      modalHandlerDelete={this.handlerDeleteReward.bind(this)}
      modalHandlerBtnTxt={this.props.getTranslate('removeModal-ButtonSubmit')}
      modalHandlerBtnLoading={this.state.loadingDeleteSumbit}
    />


    {this.state.modalAddUser ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('usersAdd-ModalBtn')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <form className="add-bonuse-user" onSubmit={this.handlerAddUser.bind(this)}>
            <div className="field-wrap">
              <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('usersAdd-ModalLabelChooseUser')}</label>
              {this.state.usersCompany ? <Select
                styles={customStyles}
                options={this.state.usersCompany}
                placeholder={this.props.getTranslate('usersAdd-ModalPlaceholderChooseUser')}
                onChange={this.handlerChooseUser.bind(this)}
                isMulti
              /> : ''}
            </div>

            <div className="field-wrap">
              <div className="field-wrap__currency-name">
                <input id="bonuse-value" className={`field-wrap__input ${this.state.bonuseValueNumError || this.state.bonuseValueCountError ? 'field-wrap__input_error' : ''}`} value={this.state.bonuseUserValue} onChange={this.handlerBonuseUser.bind(this)} placeholder={this.props.getTranslate('events-BonusesPlaceholder')} />
                {this.props.currency ? <label className="field-wrap__currency-name-item" htmlFor="bonuse-value">{this.props.currency}</label> : ''}
              </div>
              {this.state.bonuseValueNumError && !this.state.bonuseValueCountError ? <div className="validation-error">{this.props.getTranslate('validation-OnlyNum')}</div> : ''}
              {!this.state.bonuseValueNumError && this.state.bonuseValueCountError ? <div className="validation-error">{this.props.getTranslate('validation-errorRewardsCountBonuse')}</div> : ''}
            </div>
            <div className="field-wrap">
              <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">{this.props.getTranslate('usersAdd-ModalLabelMessage')}</label>
              <textarea id="description" className="field-wrap__input field-wrap__input_textarea" value={this.state.messageUserValue} onChange={this.handlerMessageUser.bind(this)} type="text" placeholder={this.props.getTranslate('usersAdd-ModalPlaceholderMessage')} />
            </div>
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="submit" disabled={this.state.loadingSubmit ? true : false}>{this.state.editIdUser && this.state.editIdLoyaltyID ? this.props.getTranslate('bunusesView-modalEditBtnSubmit') : this.props.getTranslate('bunusesView-modalAddBtnSubmit')}{this.state.loadingSubmit && <Loading />}</button>
            </div>
        </form>
        </div></div> : ''}

        {this.state.modalDeleteUser ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
          <div className="modal">
            <div className="modal-header">
              <h2 className="modal-headline">Видалити нагороду</h2>
              <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
                <svg className="close-modal__icon">
                  <use href={`${sprite}#close-icon`}></use>
                </svg>
              </button>
            </div>
            <div className="modal-reward-user">
              <div className="modal-reward-user__inner">
                <img className="modal-reward-user__avatar" src={qw === 'http:' ? this.state.deleteObj.userPhoto.replace( /https:/g, "http:" ) : this.state.deleteObj.userPhoto} alt="" />
                <div className="modal-reward-user__name">{this.state.deleteObj.userName}</div>
              </div>
            </div>
            {this.state.deleteObj.rewardByDate && this.state.deleteObj.rewardByDate.length > 1 ? <div className="modal-reward-user__dates">
              {this.state.deleteObj.rewardByDate.map((item, index) => {
                return <div className="modal-reward-user__date"><div className="unlimited-product" key={index}><div><input className="styled-checkbox" id={`styled-checkbox-${index}`} type="checkbox" checked={this.state.removeUserIds.includes(item.userRewardID)} onChange={this.handlerChangeRemoveUser.bind(this, item.userRewardID)} /><label htmlFor={`styled-checkbox-${index}`}></label></div><span><DateFormating start={item.createdDate} /></span></div></div>
              })}
            </div> : ''}
            <div className="modal-reward-user__submit">
              <button className="btn btn_theme_remove btn_width_100 btn_size_lg form-nav__submit" onClick={this.handlerRemoveUser.bind(this)}>Видалити</button>
            </div>
            </div></div> : ''}

            {this.state.modalStatusUser ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
            <div className="modal-background" onClick={this.handleModalDisabled}></div>
              <div className="modal">
                <div className="modal-header">
                  <h2 className="modal-headline">Приховати нагороду</h2>
                  <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
                    <svg className="close-modal__icon">
                      <use href={`${sprite}#close-icon`}></use>
                    </svg>
                  </button>
                </div>
                <div className="modal-reward-user">
                  <div className="modal-reward-user__inner">
                    <img className="modal-reward-user__avatar" src={qw === 'http:' ? this.state.statusObj.userPhoto.replace( /https:/g, "http:" ) : this.state.statusObj.userPhoto} alt="" />
                    <div className="modal-reward-user__name">{this.state.statusObj.userName}</div>
                  </div>
                </div>
                {this.state.statusObj.rewardByDate && this.state.statusObj.rewardByDate.length > 0 ? <div className="modal-reward-user__dates">
                  {this.state.statusObj.rewardByDate.map((item, index) => {
                    return <div className="modal-reward-user__date" key={index}>
                      <div className="modal-reward-user__date-inner">
                        <span><DateFormating start={item.createdDate} /></span>
                        {!item.enable ? <svg className="modal-reward-user__date-icon">  <use href={`${sprite}#hide-pass`}></use></svg> : ''}
                      </div>
                      <div className="tg-list-item">
                        <input id={`reward-user-status-${index}`} className="tgl tgl-light" type="checkbox" checked={item.enable} onChange={this.handlerChangeStatusUser.bind(this, index, item.enable, item.userRewardID)} /><label className="tgl-btn" htmlFor={`reward-user-status-${index}`}></label>
                      </div></div>
                  })}
                </div> : ''}
              </div></div> : ''}

    </>;
  }
}
export default (props) => <RewardsView {...useParams()} {...props} />
