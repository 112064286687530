import React from "react";
import Loading from './components/Loading';
import AccountContentHeader from './components/AccountContentHeader';
import ResultErrors from './components/ResultErrors';
import { Navigate, useLocation } from "react-router-dom";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import sprite from './media/icons.svg';
import member_1 from './media/avatar-1.png';
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  }
}),
placeholder: (provided, state) => ({
  ...provided,
  color: '#a7a7a7',
})
}

function withLocation(WrappedComponent) {
  return function(props) {
    const location = useLocation();
    return <WrappedComponent {...props} location={location} />;
  };
}

class DepositsCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getAllUsers: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("allUsers") ? true : false) : '',
      getUsersIds: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("usersIds") ? JSON.parse(localStorage.getItem("usersIds")) : []) : '',
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      depositAmount: '',
      depositMessage: '',
      errorDeposit: null,
      isLoadedDeposit: false,
      depositInfo: [],
      users: [],
      errorUsers: null,
      isLoadedUser: false,
      usersModal: false,
      modalDisabled: false,
      successSubmit: false,
      errorSubmit: null,
      successText: 'Успішно відправлено!',
      depositsData: {
        "name": 'Нарахування',
        "allUsers": false,
        "accrualType": 'SIMPLE',
        "bonusType": 'COIN',
        "recurringType": 'NEVER',
        "bonus": "",
        "message": "",
        "users": (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("usersIds") ? JSON.parse(localStorage.getItem("usersIds")) : []) : ''
      },
      currencyCompany: (typeof window !== "undefined" && typeof document !== 'undefined') ? localStorage.getItem('currencyMt') : '',
      errors: null,
      errorFields: false,
      bonuseValueNumError: false,
      bonuseValueCountError: false,
      errorDescriptionOnlySpace: false
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    document.querySelector('body').style.overflow = "";
    this.setState(prevState => ({
      modalDisabled: true,
      users: []
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        usersModal: false,
        modalDisabled: false
      }));
    }, 500);
  }

  handlerAmountValue(event) {

    let inputValue = event.target.value.replace(',', '.');

    this.setState({
      priceValueLeadingZeroError: false
    });

    if (inputValue.startsWith('0') && inputValue.length > 1 && !inputValue.startsWith('0.')) {
      this.setState({
        priceValueLeadingZeroError: true
      });
      return;
    }

    if (inputValue === "") {
        this.setState(prevState => ({
            depositsData: { ...prevState.depositsData, bonus: ""},
            bonuseValueNumError: false,
            bonuseValueCountError: false,
            changePrice: true
        }));
        return;
    }
    if (inputValue === "" || /^\d*[\.,]?\d*$/.test(inputValue)) {
        const floatValue = parseFloat(inputValue);
        if (!isNaN(floatValue)) {
            if (floatValue > 999999999.99) {
                this.setState({
                    bonuseValueCountError: true,
                    bonuseValueNumError: false
                });
            } else {
                this.setState(prevState => ({
                    depositsData: { ...prevState.depositsData, bonus: inputValue},
                    bonuseValueNumError: false,
                    bonuseValueCountError: false,
                    changePrice: true
                }));
            }
        } else {
            this.setState({
                bonuseValueCountError: false,
                bonuseValueNumError: true
            });
        }
    } else {
      const floatValue = parseFloat(inputValue);
      if (!isNaN(floatValue)) {
        this.setState({
            bonuseValueCountError: true
        });
      } else {
        this.setState({
            bonuseValueNumError: true
        });
      }
    }
  }

  handlerDescriptionValue (event) {
    this.setState(prevState => ({
      depositsData: { ...prevState.depositsData, message: event.target.value}
    }));
  }
  handlerSetAccrual (event) {
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState({
      isLoadedDeposit: true,
      bonusesLength: false,
      errorFields: false,
      errorDescriptionOnlySpace: false,
    });
    if(/^\s*$/.test(this.state.depositsData.message) && this.state.depositsData.message !== ''){
      error = true;
      this.setState({errorDescriptionOnlySpace: true });
    }
    if(this.state.depositsData && this.state.depositsData.bonus < 1){
      error = true;
    }
    if(!this.state.depositsData.allUsers && this.state.depositsData.users.length < 1){
      error = true;
    }
    if(this.state.depositsData && this.state.depositsData.bonus === ''){
      error = true;
    }
    if(!error){
      let depositsData = this.state.depositsData;
      const currentDate = new Date();
      depositsData.accrualExecutionTime = currentDate.toISOString();
      depositsData.accrualTime = currentDate.toTimeString().split(' ')[0];
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.token()
      });
      fetch(`${that.props.queryLink}/api/v1/accruals`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(depositsData)
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedDeposit: false,
              errors: data.errors,
              errorSubmit: data.errors
            });
          })
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoadedDeposit: false,
              successSubmit: true,
              depositInfo: data.data
            });
          });
          if(typeof window !== "undefined" && typeof document !== 'undefined'){
            localStorage.setItem("successDeposit", JSON.stringify({status: true, text: that.props.getTranslate('SendBonuse-SuccessLabel')}))
          }
        }
      })
    } else {
      this.setState({
        isLoadedDeposit: false,
        errorFields: true
      });
    }
  }
  handlerGetUsersModal(event){
    let that = this;
    if(!event.target.closest('.select-users__label')){
    this.setState(prevState => ({
      usersModal: !prevState.usersModal
    }));
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token()
    });
    fetch(`${that.props.queryLink}/api/v1/users/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({"itemsPerPage": 9999})
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoadedUser: true,
            errors: data.errors,
            errorUsers: data.errors
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoadedUser: true,
            users: data.data
          });
        })
      }
    })
    }
  }
  handlerGetAllUsers(){
    this.setState(prevState => ({
      getAllUsers: !prevState.getAllUsers,
      getUsersIds: [],
    }));
  }
  handlerSetUsers(){
      let ids = [];
      if(this.state.getUsersIds){
        ids = [...new Set(this.state.getUsersIds.map(users => users.id))]
      }
      this.setState(prevState => ({
        depositsData: { ...prevState.depositsData, allUsers: this.state.getAllUsers ? true : false, users: ids}
      }));
    this.handleModalDisabled();
  }
  handlerRemoveAllUsers(){
    this.setState(prevState => ({
      getAllUsers: false,
      depositsData: { ...prevState.depositsData, allUsers: false}
    }));
  }
  handlerToggleUser(index, name, id){
    if(this.state.getAllUsers){
      this.handlerRemoveAllUsers();
    }
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
    } else {
      arr = [...arr, {id: id, name: name}];
    }
    this.setState(prevState => ({
      getUsersIds: arr
    }));
  }
  handlerRemoveUser(id){
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    let ids = [];
    arr.splice(indexArr, 1);
    if(arr){
      ids = [...new Set(arr.map(item => item.id))]
    }
    this.setState(prevState => ({
      getUsersIds: arr,
      depositsData: { ...prevState.depositsData, users: ids}
    }));
  }
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successSubmit: false
    }));
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errorSubmit: null
    }));
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
      if(localStorage.getItem("usersIds")){
        localStorage.removeItem('usersIds');
        this.handlerSetUsers();
      }
      if(localStorage.getItem("allUsers")){
        localStorage.removeItem('allUsers');
        this.handlerSetUsers();
      }
    }
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('employees-AddBonusTitle') + ' | ' + this.props.companyName;
    }
    let chooseUsers = '';
    if(this.state.depositsData.users && !this.state.depositsData.allUsers){
      chooseUsers = this.state.getUsersIds.map((item, index) => {
        return <span className="select-users__label" key={index}>{item.name}<span onClick={this.handlerRemoveUser.bind(this, item.id)}><svg className="select-users__remove">
                                  <use href={`${sprite}#close-icon`}></use>
                                </svg></span></span>
      })
    }
    let users = '';
    if(this.state.usersModal){
      if(this.state.errorUsers) {
        users = <div>Ошибка...</div>;
      } else if(!this.state.isLoadedUser) {
        users = <Loading />;
      } else {
        users = <div className="users-modal">
        <div className={`${this.state.getAllUsers ? 'users-modal__item active' : 'users-modal__item'}`} onClick={this.handlerGetAllUsers.bind(this)}>
          <div className="users-modal__info">
            <div className="users-modal__avatar-icon-wrap">
              <svg className="users-modal__avatar-icon">
                <use href={`${sprite}#all-team-icon`}></use>
              </svg>
            </div>
            <span className="users-modal__name">{this.props.getTranslate('modalAddUser-AllUsersLabel')}</span>
          </div>
          {this.state.getAllUsers && <div className="users-modal__check-wrap">
            <svg className="users-modal__check">
              <use href={`${sprite}#check-icon`}></use>
            </svg>
          </div>}
        </div>
        <div>
          {this.state.users.map((item, index) => {
            let active = this.state.getUsersIds.findIndex(x => x.id === item.userID) !== -1 ? true : false;
            if(item.userStatus === 'ACTIVE'){
              return <div className={`${this.state.getAllUsers || active ? 'users-modal__item active' : 'users-modal__item'}`} key={index} onClick={this.handlerToggleUser.bind(this, index, (item.name ? item.name + ' ' : '')  + (item.middleName ? item.middleName + ' ' : '') + (item.lastName ? item.lastName : ''), item.userID)}>
                   <div className="users-modal__info">
                     <img className="users-modal__avatar" src={item.photo ? item.photo : member_1} alt="" />
                     <div className="users-modal__name">{item.name ? item.name + ' ' : ''}{item.lastName ? item.lastName + ' ' : ''}{item.middleName ? item.middleName + ' ' : ''}</div>
                   </div>
                   {this.state.getAllUsers || active ? <div className="users-modal__check-wrap">
                     <svg className="users-modal__check">
                       <use href={`${sprite}#check-icon`}></use>
                     </svg>
                   </div> : ''}
                 </div>
            }
          })}
          </div></div>
      }
    }
    const { location } = this.props;
const state = location.state || {};
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
{this.state.successSubmit && (
  <Navigate
    to={
      state.message
        ? this.props.getPermalink(`/account/messages`)
        : this.state.getUsersIds.length === 1
        ? this.props.getPermalink(`/account/users/view/${this.state.getUsersIds[0].id}`)
        : `/account/users`
    }
    replace={true}
  />
)}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
        <div className="account-content">
          <div className="account-content__inner">

            {/* Account header component */}
            <AccountContentHeader
              handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
              languageActive={this.props.chooseLanguage}
              backBtn={true}
              backBtnType="link"
              backBtnClass="button-back_theme_gray"
              backBtnHandler={this.state.getUsersIds.length === 1 ? this.props.getPermalink(`/account/users/view/${this.state.getUsersIds[0].id}`) : this.props.getPermalink(`/account/users`)}
              backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
            />

              <div className="account-content__headline-wrap">
                <h1 className="account-content__headline">{this.props.getTranslate('employees-AddBonusTitle')}</h1>
              </div>
              <div className="accrual-form-wrap">
                <form className="accrual-form" onSubmit={this.handlerSetAccrual.bind(this)}>
                  <div className="accrual-form__inner">
                    <div className="field-wrap">
                      <label className="field-wrap__label">{this.props.getTranslate('employees-AddBonusEmployeeTitle')}</label>
                      <div className={`select-users ${this.state.errorFields && (!this.state.depositsData.allUsers && this.state.depositsData.users.length < 1) ? 'field-wrap__input_error' : ''}`} onClick={this.handlerGetUsersModal.bind(this)}>
                        {this.state.depositsData.users.length < 1 ?
                        <div className="select-users__input">
                          {this.state.depositsData.allUsers && this.state.depositsData.users.length < 1 ? <span className="select-users__label">{this.props.getTranslate('getEmployees-FieldAllPlaceholder')}<span onClick={this.handlerRemoveAllUsers.bind(this)}><svg className="select-users__remove">
                                                    <use href={`${sprite}#close-icon`}></use>
                                                  </svg></span></span> : <div className="select-users__placeholder">{this.props.getTranslate('employees-AddBonusEmployeeFieldCaption')}</div>}
                        </div> : <div className="select-users__input">{chooseUsers}</div>}
                      </div>
                      {this.state.errorFields && (!this.state.depositsData.allUsers && this.state.depositsData.users.length < 1) ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                    </div>
                    <div className="field-wrap">
                      <label className="field-wrap__label" htmlFor="amount">{this.props.getTranslate('employees-AddBonusCountTitle')}</label>
                      <div className="field-wrap__amount">
                        <input id="amount" className={`field-wrap__input ${this.state.bonuseValueNumError || this.state.bonuseValueCountError || (this.state.errorFields && this.state.depositsData.bonus === '') ? 'field-wrap__input_error' : ''}`} type="text" placeholder={this.props.getTranslate('employees-AddBonusCountFieldCaption')} value={this.state.depositsData.bonus} onChange={this.handlerAmountValue.bind(this)} />
                        {this.props.currency ? <span className="field-wrap__balance-icon">{this.props.currency}</span> : ''}
                        {this.state.errorFields && this.state.depositsData.bonus === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                        {this.state.bonuseValueNumError && !this.state.bonuseValueCountError ? <div className="validation-error">{this.props.getTranslate('validation-OnlyNum')}</div> : ''}
                        {!this.state.bonuseValueNumError && this.state.bonuseValueCountError ? <div className="validation-error">{this.props.getTranslate('validation-errorBonusesCountAds')}</div> : ''}
                        {this.state.errorOnlyZero ? <div className="validation-error">{this.props.getTranslate('validation-OnlyZero')}</div> : ''}
                        {this.state.priceValueLeadingZeroError ? <div className="validation-error">{this.props.getTranslate('validation-DepositDecimal')}</div> : ''}
                      </div>
                    </div>
                    <div className="field-wrap">
                      <label className="field-wrap__label" htmlFor="message">{this.props.getTranslate('employees-AddBonusMessTitle')}</label>
                      <textarea id="message" className={`field-wrap__input field-wrap__input_accrual ${this.state.errorFields && this.state.errorDescriptionOnlySpace && this.state.depositsData.message !== '' ? 'field-wrap__input_error' : ''}`} placeholder={this.props.getTranslate('employees-AddBonusMessFieldCaption')} onChange={this.handlerDescriptionValue.bind(this)} value={this.state.depositsData.message} />
                      {this.state.errorFields && this.state.errorDescriptionOnlySpace && this.state.depositsData.message !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
                    </div>
                  </div>
                  <div className="field-wrap__submit">
                    <button className="btn btn_size_lg" type="submit" disabled={this.state.isLoadedDeposit ? true : false}>{this.props.getTranslate('employees-AddBonusSendButton')}{this.state.isLoadedDeposit && <Loading />}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
    </div>
    {this.state.usersModal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('modal-addUsersTitle')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        {users}
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerSetUsers.bind(this)}>{this.props.getTranslate('modal-addUsersBtnSubmit')}</button>
          </div>
      </div>
    </div> : ''}
    </>;
  }
}
export default withLocation(DepositsCreate);
