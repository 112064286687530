import React from "react";
import DateFormating from './DateFormating';
import LabelStatus from './LabelStatus';
import sprite from '../media/icons.svg';
class MessagesListBenefits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controlRow: null
    }
    this.handleResize = this.handleResize.bind(this);
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  componentDidUpdate() {
    const div = document.querySelector('.control-wgt');

    document.removeEventListener('click', this.handleClick);

    if (div) {
      this.handleClick = (e) => {
        const isControlBtn = e.target.closest('.control-content__btn');
        if (isControlBtn) {
          this.setState({
            controlRow: null
          });
        } else {
          const withinBoundaries = e.composedPath().includes(div);
          if (div.closest('.control-wgt') && !e.target.closest('.control-wgt')) {
            if (!withinBoundaries) {
              this.setState({
                controlRow: null
              });
            }
          }
        }
      };

      document.addEventListener('click', this.handleClick);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }
  render() {
      return <table className="massages-table">
        {this.props.tableHeaders ? <tr>
        {this.props.tableHeaders.map((item, index) => {
          return <th key={index}>{item}</th>;
        })}
          <th></th>
        </tr> : ''}
        {this.props.messagesData.map((item, index) => {
          let status = '';
          let theme = 'blue';
          if(item.messageStatus === 'NEW'){
            status = this.props.translationValueItems['NEW'];
            theme = 'blue';
          } else if(item.messageStatus === 'DONE') {
            status = this.props.translationValueItems['DONE'];
            theme = 'green';
          } else {
            status = this.props.translationValueItems['IN_PROGRESS'];
            theme = 'yellow';
          }
          return <>
          <tr className={item.response && this.props.openedMessage.indexOf(item.messageID) !== -1 ? 'active' : ''} onClick={this.props.handlerOpenMessage.bind(this, item.messageID, item.viewed, index)}>
            <td>
              {item.message.slice(0, 35)}
            </td>
            <td>{item.userName}</td>
            <td><DateFormating start={item.createdDate} time="off" /></td>
            <td>
              <LabelStatus
                name={status}
                theme={theme}
              />
            </td>
            <td className="catalog-table__control">
              <div className="data-table__control-inner">
                <div className="control-wgt">
                  <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                    <svg className="control-btn__icon">
                      <use href={`${sprite}#more-icon`}></use>
                    </svg>
                  </button>
                  {this.props.controlItemsStatus ? <div className={`control-content control-content__odd ${this.state.controlRow === index ? 'active' : ''}`}>
                    <ul className="control-content__list">
                    {this.props.controlItems && this.props.controlItems.deposit ? <li>
                      <button className="control-content__btn" onClick={this.props.controlItems.deposit.handler.bind(this, item.nominatedUserID, 'dd')}>
                        <svg className="control-content__icon">
                          <use href={`${sprite}#deposit-icon`}></use>
                        </svg>{this.props.controlItems.deposit.label}</button>
                      </li> : ''}
                      {!item.response ? <li>
                      <button className="control-content__btn" onClick={this.props.controlItems.message.handler.bind(this, item.messageID)}>
                        <svg className="control-content__icon">
                          <use href={`${sprite}#messages-icon-stroke`}></use>
                        </svg>{this.props.controlItems.message.label}</button>
                      </li> : ''}
                    </ul>
                    <ul className="control-content__list">
                    <li>
                    <button className="control-content__btn" onClick={this.props.controlItems.status.handler.bind(this)}>
                      <svg className="control-content__icon">
                        <use href={`${sprite}#hide-checkbox-icon`}></use>
                      </svg>{this.props.controlItems.status.label}</button>
                    </li>
                    </ul>
                  </div> : ''}
                </div>
              </div>
            </td>
          </tr>
          {item.response && this.props.openedMessage.indexOf(item.messageID) !== -1 ? <tr key={index}>
            <td colspan="5">
              <div className="massages-table__response">
                <div className="massages-table__response-name">Відповідь: {item.userName}</div>
                <div className="massages-table__response-txt">{item.response}</div>
              </div>
            </td>
          </tr> : ''}
          </>
        })}
      </table>;
  }
}
export default MessagesListBenefits;
