import React from "react";
import { Link } from "react-router-dom";
import sprite from '../media/icons.svg';
class AccountContentHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEnglish: false,
      targetPath: ''
    }
  }
  componentWillUpdate(prevProps, prevState) {
    let location;
    let isEnglish;
    let targetPath;
    if (typeof window !== "undefined" && typeof document !== 'undefined') {
      location = window.location.pathname;
      isEnglish = location.startsWith('/en');
      if (isEnglish) {
        targetPath = location === '/en' ? '/' : location.substring('/en'.length);
      } else {
        targetPath = `/en${location}`;
      }

      if (prevState.isEnglish !== isEnglish || prevState.targetPath !== targetPath) {
        this.setState({
          isEnglish: isEnglish,
          targetPath: targetPath
        });
      }
    }
  }

  render() {
    let location;
    let isEnglish;

    let targetPath;
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      location = window.location.pathname;
      isEnglish = location.startsWith('/en');
      if (isEnglish) {
        targetPath = location === '/en' ? '/' : location.substring('/en'.length);
      } else {
        targetPath = `/en${location}`;
      }
    }
      return <div className={`account-content__header account-content__header-wrap${this.props.preview ? ' account-content__header-preview' : ''}${this.props.addClass ? (' ' + this.props.addClass) : ''}`}>
      {this.props.prevPageBtn ? this.props.prevPageBtn : <>
      {this.props.backBtn ? (this.props.backBtnType === 'link' ? <Link className={`button-back${this.props.backBtnClass ? (' ' + this.props.backBtnClass) : ''}`} to={this.props.backBtnHandler}>
                      <svg>
                        <use href={`${sprite}#arrow-back`}></use>
                      </svg>{this.props.backBtnTxt}
                    </Link> : <button className={`button-back${this.props.backBtnClass ? (' ' + this.props.backBtnClass) : ''}`} type="button" onClick={this.props.backBtnHandler.bind(this, 'back')}>
        <svg><use href={`${sprite}#arrow-back`}></use></svg>{this.props.backBtnTxt}
        </button>) : <div className="account-content__header-inner account-content__header-category-inner">
          <h1 className="account-content__headline">{this.props.title}</h1>
          </div>}</>}
        <Link className="header__language header__language-content" to={targetPath} onClick={this.props.handlerLanguage.bind(this)}>{this.state.isEnglish ? 'EN' : 'UA'}</Link>
      </div>
  }
}
export default AccountContentHeader;
