import React from "react";
import GetEmoji from './components/GetEmoji';
import DateFormating from './components/DateFormating';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Loading from './components/Loading';
import { useParams, Link, Navigate } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import CalendarDropdown from './components/CalendarDropdown';
import sprite from './media/icons.svg';
import member_1 from './media/avatar-1.png';
import { getAuth } from "firebase/auth";

const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: '#364ed4',
    ':hover': {
      backgroundColor: '#e8ecff',
      color: '#364ed4',
    },
  })
};

class BenefitsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      successSubmit: false,
      typeValue: '',
      headlineValue: '',
      descriptionValue: '',
      amontValue: '',
      bonusTypeValue: '',
      reccValue: '',
      dropdownStatus: false,
      getAllUsers: false,
      allUsers: false,
      getUsersIds: [],
      usersIds: [],
      users: [],
      errorUser: null,
      isLoadedUser: false,
      modalDisabled: false,
      modal: false,
      modalAddMembers: false,
      chooseAllMembers: false,
      chooseMembersIds: [],
      searchValue: '',
      usersSearch: [],
      chooseDateFull: null,
      startDate: null,
      endDate:null,
      finishStartDate: null,
      finishEndDate: null,
      startTime: null,
      endTime: null,
      startTimeCalendar: '00:00',
      endTimeCalendar: '22:00',
      hoverDate: null,
      dateModal: false,
      date: new Date(),
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
      countFirstElems: null,
      countEndedElems: null,
      timeValue: '',
      usersGet: [],
      reccValues: [
        {value: 'NEVER', label: 'Не повторювати'},
        {value: 'DAILY', label: 'Кожного дня'}
      ],
      typeValues: [
        { value: 'SIMPLE', label: "Разове"},
        { value: 'RECURRING', label: "З повторенням"},
        { value: 'BIRTHDAY', label: "День народження"}
      ],
      bonuseTypes: [
        { value: 'COIN', label: 'Coin'},
        { value: 'SUPER_COIN', label: 'Super coin' }
      ]
    };
  }

  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }

  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };

  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  getNameDay(num){
    const days = [
      'неділя',
      'понеділок',
      'вівторок',
      'середа',
      'четвер',
      'п\'ятниця',
      'субота'
    ];
    return days[num];
  }
  getMonthsName(num){
    const monthes = [
      'січня',
      'лютого',
      'березня',
      'квітня',
      'травня',
      'червня',
      'липня',
      'серпня',
      'вересня',
      'жовтня',
      'листопада',
      'грудня'
    ];
    return monthes[num];
  }
  handleTypeBenefit(event){
    this.setState({
      typeValue: event.value
    });
  }

  handlerHeadlineValue(event) {
    this.setState({
      headlineValue: event.target.value
    });
  }

  handlerDescriptionValue(event) {
    this.setState({
      descriptionValue: event.target.value
    });
  }

  handlerAmountValue(event){
    this.setState({
      amontValue: event.target.value
    });
  }

  handleBonusTypeValueBenefit(event){
    this.setState({
      bonusTypeValue: event.value
    });
  }
  handleReccValue(event){
    this.setState({
      reccValue: event.value
    });
  }  
  handlerGetStartDate(iter, day, month, year, next, prev, event){
      event.preventDefault();
      let monthValue = month + 1;
      if(next){
        monthValue = monthValue + 1;
        if(monthValue === 13) {
          monthValue = 1;
        }
      } else if(prev){
        monthValue = monthValue - 1;
        if(monthValue === 0) {
          monthValue = 12;
        }
      } else {
        monthValue = monthValue;
      }
      let nthWeekday = this.getNthWeekdayOfMonth(new Date(year, (monthValue - 1), day));
      this.setState({
        startDateCalendar: day + '.' + monthValue + '.' + year,
        iterStartCalendar: iter,
        weeklyDay: new Date(year, (monthValue - 1), day).getDay(),
        nthWeekday: nthWeekday - 1,
        dateNum: day,
        monthNum: month
      });
    }

    handlerGetStartTime(time, event){
      event.preventDefault();
      this.setState({
        startTimeCalendar: time
      });
    }

    handlerGetEndDate(iter, day, month, year, next, prev, event){
      event.preventDefault();
      let monthValue = month + 1;
      if(next){
        monthValue = monthValue + 1;
        if(monthValue === 13) {
          monthValue = 1;
        }
      } else if(prev){
        monthValue = monthValue - 1;
        if(monthValue === 0) {
          monthValue = 12;
        }
      } else {
        monthValue = monthValue;
      }
      this.setState({
        endDateCalendar: day + '.' + monthValue + '.' + year,
        iterFinishCalendar: iter
      });
    }

    handlerGetEndTime(time, event){
      event.preventDefault();
      this.setState({
        endTimeCalendar: time
      });
    }

    getNameNumberDay(weekday, num){
      const numberNameOne = [
        'перший',
        'другий',
        'третій',
        'чертвертий',
        'п\'ятий'
      ];
      const numberNameSecond = [
        'перша',
        'друга',
        'третя',
        'чертверта',
        'п\'ята'
      ];
      if(weekday === 1 || weekday === 2 || weekday === 4){
        return numberNameOne[num];
      } else {
        return numberNameSecond[num];
      }
    }

    getNthWeekdayOfMonth(date) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const weekday = date.getDay();
      let nthWeekday = 0;

      for (let i = 1; i <= date.getDate(); i++) {
        const currentDate = new Date(year, month, i);
        if (currentDate.getDay() === weekday) {
          nthWeekday++;
          if (currentDate.getTime() === date.getTime()) {
            return nthWeekday;
          }
        }
      }

      return null;
    }

    handlerChangDateInfo(status){
      this.setState(prevState => ({
        changeStatus: status
      }));
    }

  getNthWeekdayOfMonth(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const weekday = date.getDay();
    let nthWeekday = 0;

    for (let i = 1; i <= date.getDate(); i++) {
      const currentDate = new Date(year, month, i);
      if (currentDate.getDay() === weekday) {
        nthWeekday++;
        if (currentDate.getTime() === date.getTime()) {
          return nthWeekday;
        }
      }
    }

    return null;
  }

  handlerChangDateInfo(status){
    this.setState(prevState => ({
      changeStatus: status
    }));
  }

  handlerAddMembers (event) {
    event.preventDefault();
    let that = this;
    if(!event.target.closest('.select-users__label')){
      this.setState({
        modal: true,
        modalAddMembers: true,
        users: [],
        isLoadedUser: false,
        errorUsers: null
      });
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedUser: true,
              errorUsers: data.errors,
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoadedUser: true,
              users: data
            })
          });
        }
      })
    })
    }
  }
  setChooseMembers (event) {
    this.handleModalDisabled();
  }

  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token()
    });
    fetch(`${that.props.queryLink}/api/v1/accruals/${that.props.id}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            error: true,
            isLoaded: true
          });
        })
      } else {
        res.json().then(function(data) {
          let startDate = new Date(data.accrualExecutionTime);
          let startDateFull = startDate.getDate() + '.' + (startDate.getMonth() + 1) + '.' + startDate.getFullYear();
          let weeklyDayStart = startDate.getDay();
          let nthWeekdayStart = that.getNthWeekdayOfMonth(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
          that.setState( {
            startDateCalendar: startDateFull,
            startTimeCalendar: data.accrualTime.replace(/:00$/, ''),
            weeklyDay: weeklyDayStart,
            nthWeekday: nthWeekdayStart - 1,
            dateNum: startDate.getDate(),
            monthNum: startDate.getMonth(),
            headlineValue: data.name,
            descriptionValue: data.message,
            amountValue: data.bonus,
            reccValue: data.recurringType ? that.state.reccValues[that.state.reccValues.findIndex(el => el.value === data.recurringType)].value : null,
            typeValue: data.accrualType ? that.state.typeValues[that.state.typeValues.findIndex(el => el.value === data.accrualType)].value : null,
            bonusTypeValue: data.bonusType ? that.state.bonuseTypes[that.state.bonuseTypes.findIndex(el => el.value === data.bonusType)].value : null,
            usersIds: data.users ? data.users : null,
            getUsersIds: data.users ? data.users : null,
            allUsers: data.allUsers
          });

          fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
            method: 'GET',
            headers: myHeaders
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              res.json().then(function(data) {
                let users = data.map(x => x.users);
                let usersIDs = users.map(x => {
                  return x.map(id => {
                    return {id: id.userID, name: id.name};
                  })
                });
                var result3 = [].concat(...usersIDs);
                let ids = that.state.usersIds;
                var usersActive = result3.map(user => {
                  if(ids.indexOf(user.id) !== -1){
                    return user;
                  }
                });
                usersActive = usersActive.filter(function(x) {
                     return x !== undefined;
                });
                that.setState({
                  usersGet: usersActive,
                  getUsersIds: usersActive
                });
              })
            }
          })

        })
      }
    })
  }

  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalAddMembers: false,
        modalDisabled: false,
        modalData: {}
      }));
    }, 500);
  }

  handlerGetAllUsers(){
    this.setState(prevState => ({
      getAllUsers: !prevState.getAllUsers,
      getUsersIds: [],
    }));
  }
  handlerToggleUser(index, name, id){
    if(this.state.getAllUsers){
      this.handlerRemoveAllUsers();
    }
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
    } else {
      arr = [...arr, {id: id, name: name}];
    }
    this.setState(prevState => ({
      getUsersIds: arr
    }));
  }
  handlerRemoveAllUsers(){
    this.setState(prevState => ({
      getAllUsers: false,
      allUsers: false
    }));
  }
  handlerRemoveUser(id){
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    let ids = [];
    arr.splice(indexArr, 1);
    if(arr){
      ids = [...new Set(arr.map(item => item.id))]
    }
    this.setState(prevState => ({
      getUsersIds: arr,
      usersIDs: ids
    }));
  }
  handlerSetUsers(){
      let ids = [];
      if(this.state.getUsersIds){
        ids = [...new Set(this.state.getUsersIds.map(users => users.id))]
      }
      this.setState(prevState => ({
        allUsers: this.state.getAllUsers ? true : false,
        usersIds: ids
      }));
    this.handleModalDisabled();
  }

  handlerSearchUsers(event) {
    let arr = this.state.users;
    let newArr = [];
    this.setState({
      searchValue: event.target.value,
      usersSearch: []
    });
    let that = this;
    arr.map(function(item) {
      item.users.map(function(users) {
        if (users.name.includes(event.target.value)) {
           newArr.push(users);
          that.setState(prevState => ({
            usersSearch: [...prevState.usersSearch, users]
          }));
        }
      })
    });
  }
  handlerToggleUsersTeam(teamID, event) {
      const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
      const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
      const existingIds = new Set(this.state.getUsersIds.map(user => user.id));
      const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
      let updatedGetUsersIds;
      if (filteredUsers.length > 0) {
          updatedGetUsersIds = this.state.getUsersIds.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
      } else {
          updatedGetUsersIds = [...this.state.getUsersIds, ...newUsers];
      }
      this.setState({ getUsersIds: updatedGetUsersIds });
  }

  handlerHoverEnterUserTeam(teamID, event){
    const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
    const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
    const existingIds = new Set(this.state.getUsersIdsHover.map(user => user.id));
    const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
    let updatedGetUsersIds;
    if (filteredUsers.length > 0) {
        updatedGetUsersIds = this.state.getUsersIdsHover.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
    } else {
        updatedGetUsersIds = [...this.state.getUsersIdsHover, ...newUsers];
    }
    this.setState({ getUsersIdsHover: updatedGetUsersIds });
  }

  handlerHoverLeaveUserTeam(event){
    this.setState({ getUsersIdsHover: [] });
  }
  handleSubmit(event){
    event.preventDefault();
    let startDate = this.state.startDateCalendar.split('.').reverse().join('-') + ' ' + this.state.startTimeCalendar + ':00';
    startDate = new Date(startDate).toISOString();
    let submitObj = {
      "name": this.state.headlineValue,
      "message": this.state.descriptionValue,
      "accrualType": this.state.typeValue,
      "bonusType": this.state.bonusTypeValue,
      "bonus": this.state.amountValue,
      "accrualExecutionTime": startDate,
      "accrualTime": this.state.startTimeCalendar + ':00',
      "users": this.state.usersIds,
      "allUsers": this.state.getAllUsers,
      "accrualID": Number(this.props.id),
      "enabled": true
    };
    submitObj.accrualExecutionTime = new Date(submitObj.accrualExecutionTime).toISOString();
    if(this.state.typeValue === 'RECURRING'){
      submitObj.recurringType = this.state.reccValue;
    } else {
      submitObj.recurringType = "NEVER";
    }
    console.log(submitObj);
    let that = this;
    this.setState({
      isLoadedSubmit: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      let location;
    let language = 'uk-UA';
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
    location = document.location.pathname;
    const isEnglish = location.startsWith('/en');
    if (isEnglish) {
    language = 'en-US'
    }
    }
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken,
          'Accept-Language': language
      });
      fetch(`${that.props.queryLink}/api/v1/accruals/${that.props.id}`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(submitObj)
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.message
            })
          });
        } else {
          if(typeof window !== "undefined" && typeof document !== 'undefined'){
            localStorage.setItem("successBenefitsSubmit", JSON.stringify({status: true, text: 'Успішно створено'}))
          }
          that.setState({
            isLoadedSubmit: false,
            successSubmit: true
          });
          setTimeout(() => {
            that.setState({
              successSubmit: false
            });
          }, 5000);
        }
      })
    })

  }
  handleTimeValue(event){
    this.setState({
      startTimeCalendar: event.value
    });
  }
  render() {
    const type = [
      { value: 'SIMPLE', label: this.props.getTranslate('accrualOptionsType-Simple')},
      { value: 'RECURRING', label: this.props.getTranslate('accrualOptionsType-Recurring')},
      { value: 'BIRTHDAY', label: this.props.getTranslate('accrualOptionsType-Birthday')}
    ];
    const reccValues = [
      {value: 'NEVER', label: this.props.getTranslate('accrualOptionsRepeat-NEVER')},
      {value: 'DAILY', label: this.props.getTranslate('accrualOptionsRepeat-DAILY')}
    ];
    const timeValues = [
      {value: '00:00', label: '00:00'},
      {value: '01:00', label: '01:00'},
      {value: '02:00', label: '02:00'},
      {value: '03:00', label: '03:00'},
      {value: '04:00', label: '04:00'},
      {value: '05:00', label: '05:00'},
      {value: '06:00', label: '06:00'},
      {value: '07:00', label: '07:00'},
      {value: '08:00', label: '08:00'},
      {value: '09:00', label: '09:00'},
      {value: '10:00', label: '10:00'},
      {value: '11:00', label: '11:00'},
      {value: '12:00', label: '12:00'},
      {value: '13:00', label: '13:00'},
      {value: '14:00', label: '14:00'},
      {value: '15:00', label: '15:00'},
      {value: '16:00', label: '16:00'},
      {value: '17:00', label: '17:00'},
      {value: '18:00', label: '18:00'},
      {value: '19:00', label: '19:00'},
      {value: '20:00', label: '20:00'},
      {value: '21:00', label: '21:00'},
      {value: '22:00', label: '22:00'},
      {value: '23:00', label: '23:00'}
    ];
    let chooseUsers = '';
    if(this.state.usersGet.length > 0 && !this.state.allUsers){
      chooseUsers = this.state.usersGet.map((item, index) => {
        return <span className="select-users__label" key={index}>{item.name}<span onClick={this.handlerRemoveUser.bind(this, item.id)}><svg className="select-users__remove">
                                  <use href={`${sprite}#close-icon`}></use>
                                </svg></span></span>
      })
    }
    let users = '';
    if(this.state.errorUsers) {
      users = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedUser) {
      users = <Loading />;
    } else {
      users = this.state.searchValue ?       <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        {this.state.usersSearch.map((elem, index) => {
          let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            return <div className={`${active ? 'users-modal__item active' : 'users-modal__item'}`} key={index} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                 <div className="users-modal__info">
                   <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                   <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                 </div>
                 {active ? <div className="users-modal__check-wrap">
                   <svg className="users-modal__check">
                     <use href={`${sprite}#check-icon`}></use>
                   </svg>
                 </div> : ''}
               </div>
        })}
      </div></Scrollbars> : <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        <div className={`${this.state.getAllUsers ? 'users-modal__item active' : 'users-modal__item'}`} onClick={this.handlerGetAllUsers.bind(this)}>
          <div className="users-modal__info">
            <div className="users-modal__avatar-icon-wrap">
              <svg className="users-modal__avatar-icon">
                <use href={`${sprite}#all-team-icon`}></use>
              </svg>
            </div>
            <span className="users-modal__name">{this.props.getTranslate('modalAddUser-AllUsersLabel')}</span>
          </div>
          {this.state.getAllUsers && <div className="users-modal__check-wrap">
            <svg className="users-modal__check">
              <use href={`${sprite}#check-icon`}></use>
            </svg>
          </div>}
        </div>
        {this.state.users.map((item, index) => {
         return item.users.length > 0 ? <div key={index}>
         <div className="team-list-modal__label" onClick={this.handlerToggleUsersTeam.bind(this, item.teamID)} onMouseEnter={this.handlerHoverEnterUserTeam.bind(this, item.teamID)} onMouseLeave={this.handlerHoverLeaveUserTeam.bind(this)}>{item.name ? item.name : this.props.getTranslate('modalAddUser-NoTeamLabel')}</div>
          {item.users.map((elem, i) => {
            let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            let hoverActive = this.state.getUsersIdsHover && this.state.getUsersIdsHover.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
              return <div className={`${this.state.getAllUsers || active || hoverActive ? 'users-modal__item active' : 'users-modal__item'}`} key={i} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                   <div className="users-modal__info">
                     <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                     <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                   </div>
                   {this.state.getAllUsers || active ? <div className="users-modal__check-wrap">
                     <svg className="users-modal__check">
                       <use href={`${sprite}#check-icon`}></use>
                     </svg>
                   </div> : ''}
                 </div>
          })}
        </div> : ''})}
       </div></Scrollbars>;
    }

    return (
      <>
        <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
        {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={this.props.getPermalink("/account/accrual")} replace={true} />) : ''}
        <AccountHeader
          queryLink={this.props.queryLink}
          status={this.state.mobileMenu}
          statusStart={this.state.mobileMenuStart}
          handler={this.handlerMobileMenu.bind(this)}
          getTranslate={this.props.getTranslate.bind(this)}
          completed={this.props.completed}
        />
        <div className="account-wrap">
          <AccountNav
            getPermalink={this.props.getPermalink}
            queryLink={this.props.queryLink}
            configsApp={this.props.configsApp}
            newReview={this.props.newReview}
            companyName={this.props.companyName}
            token={this.props.token()}
            status={this.state.mobileMenu}
            statusStart={this.state.mobileMenuStart}
            handler={this.handlerMobileMenu.bind(this)}
            dropdownStatus={this.state.dropdownStatus}
            dropdownHandler={this.handlerDropdown.bind(this)}
            getTranslate={this.props.getTranslate}
            completed={this.props.completed}
          />
          <div className="account-content">
            <div className="account-content__inner">
              <AccountContentHeader
                handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
                languageActive={this.props.chooseLanguage}
                backBtn={true}
                backBtnType="link"
                backBtnClass="button-back_theme_gray"
                backBtnHandler={this.props.getPermalink("/account/benefits")}
                backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
              />
              <div className="account-content__header-title">
                <h1 className="account-content__headline">{this.props.getTranslate('accrualEdit-Title')}</h1>
              </div>
              <div className="event-form-wrap">
                <div className="event-form">
                  <div className="event-form__inner">
                    <div className="event-form__row">
                      <div className="field-wrap">
                        <div className="field-wrap__label-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="type">{this.props.getTranslate('accrualForm-TypeLabel')}</label>
                          <div className="tooltip-info">
                            <span className="tooltip-info__icon">?</span>
                            <div className="tooltip-info__content">
                              <div className="arrow"></div>
                              <p>{this.props.getTranslate('accrualForm-TypeTooltip')}</p>
                            </div>
                          </div>
                        </div>
                        <Select
                          styles={customStyles}
                          options={type}
                          placeholder={this.props.getTranslate('accrualForm-TypePlaceholder')}
                          onChange={this.handleTypeBenefit.bind(this)}
                          value={type ? type[type.findIndex(i => i.value === this.state.typeValue)] : this.state.typeValue[0]}
                        />
                      </div>
                    </div>
                      <div className="field-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">{this.props.getTranslate('accrualForm-NameLabel')}</label>
                          <input id="headline" className="field-wrap__input field-wrap__input_width_66" type="text" value={this.state.headlineValue} onChange={this.handlerHeadlineValue.bind(this)} placeholder={this.props.getTranslate('accrualForm-NamePlaceholder')} />
                    </div>
                                          <div className="field-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">{this.props.getTranslate('accrualForm-DescriptionLabel')}</label>
                          <textarea id="description" className="field-wrap__input field-wrap__input_event field-wrap__input_width_66" type="text" value={this.state.descriptionValue} onChange={this.handlerDescriptionValue.bind(this)} placeholder={this.props.getTranslate('accrualForm-DescriptionPlaceholder')} />
                    </div>
                    <div className="event-form__row">
                    <div className="field-wrap">
                        <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="amount">{this.props.getTranslate('accrualForm-AmountLabel')}</label>
                        <input id="amount" className="field-wrap__input" type="text" value={this.state.amountValue} onChange={this.handlerAmountValue.bind(this)} placeholder={this.props.getTranslate('accrualForm-AmountPlaceholder')} />
                  </div>
                  <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="repeat">{this.props.getTranslate('accrualForm-TypeLabel')}</label>
                  <Select
                    styles={customStyles}
                    options={this.state.bonuseTypes}
                    placeholder={this.props.getTranslate('accrualForm-TypePlaceholder')}
                    onChange={this.handleBonusTypeValueBenefit.bind(this)}
                    value={this.state.bonuseTypes ? this.state.bonuseTypes[this.state.bonuseTypes.findIndex(i => i.value === this.state.bonusTypeValue)] : this.state.bonuseTypes[0]}
                  />
                  </div>
                    </div>

                    <div className="event-form__row">

                      {this.state.typeValue === 'RECURRING' ?

                        <div className="field-wrap">
                        <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="repeat">{this.props.getTranslate('accrualForm-ReccuringLabel')}</label>
                        <Select
                          styles={customStyles}
                          options={reccValues}
                          placeholder={this.props.getTranslate('accrualForm-ReccuringPlaceholder')}
                          onChange={this.handleReccValue.bind(this)}
                          value={reccValues ? reccValues[reccValues.findIndex(i => i.value === this.state.reccValue)] : this.state.reccValues[0]}
                        />
                        </div>

                       : ''}

                      {this.state.typeValue !== 'BIRTHDAY' ?                   <CalendarDropdown
                                          label={this.props.getTranslate('events-Date')}
                                          handlerGetStartDate={this.handlerGetStartDate.bind(this)}
                                          handlerGetEndDate={this.handlerGetEndDate.bind(this)}
                                          handlerGetStartTime={this.handlerGetStartTime.bind(this)}
                                          handlerGetEndTime={this.handlerGetEndTime.bind(this)}
                                          startDate={this.state.startDateCalendar}
                                          startTime={this.state.startTimeCalendar}
                                          endDate={this.state.endDateCalendar}
                                          endTime={this.state.endTimeCalendar}
                                          iterStartCalendar={this.state.iterStartCalendar}
                                          iterFinishCalendar={this.state.iterFinishCalendar}
                                          handlerChangeDateForInfo={this.handlerChangDateInfo.bind(this)}
                                          offEndDate={true}
                                        /> : ''}
                      {this.state.typeValue === 'BIRTHDAY' ? <div className="field-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="repeat">{this.props.getTranslate('accrualForm-DateLabel')}</label>
                      <Select
                        styles={customStyles}
                        options={timeValues}
                        placeholder={this.props.getTranslate('accrualForm-DatePlaceholder')}
                        onChange={this.handleTimeValue.bind(this)}
                      />
                      </div> : ''}
                    </div>

                    <div className="event-form__row">
                      <div className="field-wrap field-wrap__members">
                        <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('events-Members')}</label>
                        <div className={`select-modal ${this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? 'field-wrap__input_error' : ''}`} onClick={this.handlerAddMembers.bind(this)}>
                        {this.state.getUsersIds.length < 1 ?
                        <div className="select-users__input">
                          {this.state.allUsers && this.state.usersIds.length < 1 ? <span className="select-users__label">{this.props.getTranslate('getEmployees-FieldAllPlaceholder')}<span onClick={this.handlerRemoveAllUsers.bind(this)}><svg className="select-users__remove">
                                                    <use href={`${sprite}#close-icon`}></use>
                                                  </svg></span></span> : <div className="select-users__placeholder">{this.props.getTranslate('getEmployees-FieldSelectPlaceholder')}</div>}
                        </div> : <div className="select-users__input">{chooseUsers}</div>}
                        </div>
                        {this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                      </div>
                    </div>

                  </div>
                  <div className="field-wrap__submit event-form__nav">
                    <button className="btn btn_size_lg" type="button" onClick={this.handleSubmit.bind(this)}>{this.props.getTranslate('accrualForm-EditBtnSubmit')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.modal && this.state.modalAddMembers ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.getTranslate('modal-addUsersTitle')}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <div className="field-wrap field-wrap__search-members">
            <input className="field-wrap__input" type="text" value={this.state.searchValue} onChange={this.handlerSearchUsers.bind(this)} placeholder={this.props.getTranslate('modal-addUsersSearchPlaceholder')} />
            <svg className="field-wrap__search-icon">
              <use href={`${sprite}#search-icon`}></use>
            </svg>
          </div>
          <div className="question-members">
            <div className="question-members-wgt">
              {users}
            </div>
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerSetUsers.bind(this)}>{this.props.getTranslate('modal-addUsersBtnSubmit')}</button>
            </div>
          </div>
        </div>
      </div> : ''}

      </>
    );
  }
}
export default (props) => <BenefitsEdit {...useParams()} {...props} />
